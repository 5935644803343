import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.17_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.48.2_react-dom@18_bqe3ed2meuut7bl54jgums44xi/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.17_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.48.2_react-dom@18_bqe3ed2meuut7bl54jgums44xi/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.17_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.48.2_react-dom@18_bqe3ed2meuut7bl54jgums44xi/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.17_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.48.2_react-dom@18_bqe3ed2meuut7bl54jgums44xi/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.17_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.48.2_react-dom@18_bqe3ed2meuut7bl54jgums44xi/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.17_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.48.2_react-dom@18_bqe3ed2meuut7bl54jgums44xi/node_modules/next/dist/client/components/render-from-template-context.js");
